import { GB } from '@clearscore-group/lib.config.i18n';

import DepartmentInfo from '../components/department-info';

export default {
    [GB]: {
        intro: {
            ctaHref: 'https://medium.com/clearscore',
        },
        departmentSections: [
            {
                key: 'engineering',
                theme: DepartmentInfo.themes.SAND,
                imageKey: 'tech_hero',
                logos: ['react', 'aws', 'node_js', 'swift', 'kotlin', 'scala'],
                waysOfWorking: ['maximiseLearning', 'frequentReleases', 'removeLegacy'],
                video: 'tech',
                videoThumbnail: 'thumbnail',
                stories: [{ key: 'peter_m' }, { key: 'lydia' }, { key: 'peter_b' }],
            },
            {
                key: 'design',
                theme: DepartmentInfo.themes.CHILL,
                imageKey: 'design_hero',
                waysOfWorking: ['userFirst', 'systemApproach', 'crossFunctional'],
                logos: ['sketch', 'abstract', 'figma', 'miro', 'creative_cloud', 'overflow', 'principle', 'framer'],
            },
        ],
        jobListings: {
            viewAllJobs: '/careers/jobs?expand=1',
            maxJobsDisplayed: 5,
            search: 'Tech|Design',
        },
    },
};
