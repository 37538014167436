import React, { Fragment } from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import CareersTechAndDesign from '@clearscore/website.careers-tech-and-design';

import Layout from '../components/layout';
import techTeamVideo from '../assets/global/careers/tech-and-design/tech_team_video.mp4';
import { findImage, findStory, HiddenPageMeta } from './template-util';

const CareersTechAndDesignTemplate = ({
    data,
    pageContext: { market, subNavRoutes, verticalVersion },
    location,
}: PageProps<
    Queries.CareersTechAndDesignQuery,
    { market: string; verticalVersion: string; subNavRoutes: Array<{ navTitle: string; categoryUrl: string }> }
>): React.ReactElement => {
    const findLogo = (
        logoName: string,
    ): {
        readonly name: string;
        readonly publicURL: string;
    } => data.logoImages.nodes.find(({ name }) => name === `logo_${logoName}`);

    const images = {
        hero: findImage(data, 'hero'),
        thumbnail: findImage(data, 'thumbnail'),
        tech_hero: findImage(data, 'tech_hero'),
        design_hero: findImage(data, 'design_hero'),
        logos: {
            swift: findLogo('swift'),
            aws: findLogo('aws'),
            kotlin: findLogo('kotlin'),
            node_js: findLogo('node_js'),
            react: findLogo('react'),
            scala: findLogo('scala'),
            abstract: findLogo('abstract'),
            creative_cloud: findLogo('creative_cloud'),
            figma: findLogo('figma'),
            framer: findLogo('framer'),
            miro: findLogo('miro'),
            overflow: findLogo('overflow'),
            principle: findLogo('principle'),
            sketch: findLogo('sketch'),
        },
        stories: {
            ...findStory(data, 'lydia'),
            ...findStory(data, 'peter_m'),
            ...findStory(data, 'peter_b'),
        },
    };

    const videos = {
        tech: techTeamVideo,
    };

    return (
        <Layout
            meta={{
                title: `Engineering and Design Careers | ClearScore ${market.toUpperCase()}`,
                description: '',
            }}
            location={location.pathname}
        >
            {({ commonModules }): React.ReactElement => (
                <Fragment>
                    <CareersTechAndDesign
                        images={images}
                        videos={videos}
                        appStoreConfig={commonModules.app_store}
                        cookiePolicyConfig={commonModules.cookie_policy}
                        categories={subNavRoutes}
                    />
                    <HiddenPageMeta verticalVersion={verticalVersion} />
                </Fragment>
            )}
        </Layout>
    );
};

export const query = graphql`
    query CareersTechAndDesign {
        images: allFile(filter: { absolutePath: { regex: "/global/careers/tech-and-design/" } }) {
            nodes {
                name
                childImageSharp {
                    gatsbyImageData(quality: 99, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
                }
            }
        }
        logoImages: allFile(filter: { absolutePath: { regex: "/global/careers/tech-and-design/logo_*/" } }) {
            nodes {
                name
                publicURL
            }
        }
    }
`;

export default CareersTechAndDesignTemplate;
