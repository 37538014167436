import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Spacer from '@clearscore/ui.rainbow.spacer';
import Text from '@clearscore/ui.rainbow.text';

import styles from './department-info.module.css';

const DepartmentInfo = ({ header, subheader, waysOfWorking, logos, theme }) => (
    <Fragment>
        <section data-id="department-info" className={cx(styles.wrapper, styles[`isTheme${theme}`])}>
            <h2 className={styles.headerText}>{header}</h2>
            <Spacer
                all={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.BIG }}
                medium={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.SUPER }}
            >
                <div className={styles.contentText}>
                    <Text.Body1>{subheader}</Text.Body1>
                </div>
            </Spacer>
            <div className={styles.waysOfWorking}>
                {waysOfWorking.map((way) => (
                    <Spacer
                        key={way.header}
                        medium={{ bottom: Spacer.spacings.SUPER, right: Spacer.spacings.SUPER }}
                        all={{ bottom: Spacer.spacings.BIG }}
                    >
                        <Spacer all={{ bottom: Spacer.spacings.MICRO }}>
                            <div className={styles.headerText}>
                                <Text.H2 tag="h3">{way.header}</Text.H2>
                            </div>
                        </Spacer>
                        <div className={styles.contentText}>
                            <Text.Body1>{way.subheader}</Text.Body1>
                        </div>
                    </Spacer>
                ))}
            </div>
            <div className={styles.logos}>
                {logos.map(({ name, publicURL }) => (
                    <img key={publicURL} src={publicURL} alt={name} />
                ))}
            </div>
        </section>
    </Fragment>
);

DepartmentInfo.themes = {
    SAND: 'Sand',
    CHILL: 'Chill',
};

DepartmentInfo.propTypes = {
    header: PropTypes.string.isRequired,
    subheader: PropTypes.string.isRequired,
    waysOfWorking: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            subheader: PropTypes.string.isRequired,
        }),
    ).isRequired,
    logos: PropTypes.arrayOf(PropTypes.shape({ publicURL: PropTypes.string.isRequired })).isRequired,
    theme: PropTypes.oneOf(Object.values(DepartmentInfo.themes)).isRequired,
};

export default DepartmentInfo;
