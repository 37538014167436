import React, { Fragment } from 'react';
import Template from '@clearscore/shared.website-template';
import {
    useTracking,
    CareersNav,
    PageHeader,
    SectionHeader,
    FullWidthImage,
    PlayableVideo,
    JobListings,
    StoriesSection,
    GLASSDOOR_RATING,
} from '@clearscore/shared.website-careers-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import Text from '@clearscore/ui.rainbow.text';
import Spacer from '@clearscore/ui.rainbow.spacer';

import DepartmentInfo from './components/department-info';
import marketConfig from './lib/market-config';
import { PAGE_NAMESPACE } from './lib/constants';
import styles from './careers-tech-and-design.module.css';

const CareersTechAndDesign = ({ images, videos, appStoreConfig, cookiePolicyConfig, categories }) => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const {
        intro: introConfig,
        departmentSections: departmentSectionsConfig,
        jobListings: jobListingsConfig,
    } = useMarketConfig(marketConfig);
    const { trackCareersContentClicked } = useTracking();

    const getLogoImages = (logoNames) => logoNames.map((logo) => images.logos[logo]);

    const getDepartmentStories = (departmentKey, departmentStoriesConfig) => {
        const departmentCopy = t(`departmentSections.${departmentKey}`);
        return departmentStoriesConfig.map(({ key }) => ({
            image: images.stories[key],
            ...departmentCopy.stories.items[key],
        }));
    };
    const jobListingsCopy = {
        ...t('workable'),
        glassdoorText: t('workable.glassdoorText', { rating: GLASSDOOR_RATING }),
    };

    return (
        <Template shouldHideNav appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig}>
            <CareersNav items={categories} theme={CareersNav.Theme.LIGHT} />
            <article data-layout="full-bleed">
                <Spacer all={{ vertical: Spacer.spacings.SUPER }} large={{ vertical: Spacer.spacings.JUMBO }}>
                    <PageHeader preheader={t('pageHeader.preheader')} header={t('pageHeader.header')} />
                </Spacer>
                <section className={styles.heroImage}>
                    <FullWidthImage image={images.hero} alt={t('intro.imageAlt')} />
                </section>
                <SectionHeader headerTag="h2" header={t('intro.header')} subheader={t('intro.subheader')}>
                    <Text.Link
                        onClick={() =>
                            trackCareersContentClicked({
                                type: 'link',
                                description: t('intro.ctaText'),
                                href: introConfig.ctaHref,
                            })
                        }
                        isNewTab
                        href={introConfig.ctaHref}
                    >
                        {t('intro.ctaText')}
                    </Text.Link>
                </SectionHeader>
                {departmentSectionsConfig.map(
                    ({ key, imageKey, logos, video, videoThumbnail, stories, theme, waysOfWorking }) => (
                        <Fragment key={key}>
                            <section>
                                <FullWidthImage
                                    image={images[imageKey]}
                                    alt={t(`departmentSections.${key}.imageAlt`)}
                                />
                            </section>
                            <DepartmentInfo
                                logos={getLogoImages(logos)}
                                theme={theme}
                                header={t(`departmentSections.${key}.header`)}
                                subheader={t(`departmentSections.${key}.subheader`)}
                                waysOfWorking={waysOfWorking.map((item) =>
                                    t(`departmentSections.${key}.waysOfWorking.${item}`),
                                )}
                            />
                            {video && videoThumbnail && (
                                <PlayableVideo
                                    thumbnail={images[videoThumbnail]}
                                    video={videos[video]}
                                    thumbnailHeader={t(`departmentSections.${key}.thumbnailHeader`)}
                                    thumbnailSubheader={t(`departmentSections.${key}.thumbnailSubheader`)}
                                />
                            )}
                            {stories && (
                                <Spacer
                                    all={{ vertical: Spacer.spacings.SUPER, horizontal: Spacer.spacings.BIG }}
                                    medium={{ vertical: Spacer.spacings.JUMBO, horizontal: Spacer.spacings.SUPER }}
                                >
                                    <StoriesSection
                                        items={getDepartmentStories(key, stories)}
                                        header={t(`departmentSections.${key}.stories.header`)}
                                        subheader={t(`departmentSections.${key}.stories.subheader`)}
                                    />
                                </Spacer>
                            )}
                        </Fragment>
                    ),
                )}
                <JobListings language={jobListingsCopy} config={jobListingsConfig} />
            </article>
        </Template>
    );
};

CareersTechAndDesign.propTypes = {
    images: PropTypes.shape({
        hero: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
        thumbnail: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
        logos: PropTypes.shape({
            react: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            aws: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            node_js: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            swift: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            kotlin: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            scala: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            sketch: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            abstract: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            figma: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            miro: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            creative_cloud: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            overflow: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            principle: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
            framer: PropTypes.shape({ publicURL: PropTypes.string.isRequired }).isRequired,
        }).isRequired,
        stories: PropTypes.shape({
            peter_m: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
            lydia: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
            peter_b: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    videos: PropTypes.object.isRequired,
    appStoreConfig: PropTypes.shape({
        googlePlayUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        appStoreLink: PropTypes.string,
        playStoreLink: PropTypes.string,
        appleStoreToggle: PropTypes.bool,
        googleStoreToggle: PropTypes.bool,
    }).isRequired,
    cookiePolicyConfig: PropTypes.shape({
        moreInfoLinkUrl: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
    }).isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            categoryNavPosition: PropTypes.number.isRequired,
            categoryUrl: PropTypes.string.isRequired,
            navTitle: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

CareersTechAndDesign.defaultProps = {};

export default CareersTechAndDesign;
