import i18next from 'i18next';
import { EN_GB } from '@clearscore-group/lib.config.i18n';

import { PAGE_NAMESPACE } from './lib/constants';
import CareersTechAndDesign from './careers-tech-and-design';
import copyEnGb from './copy/pages/careers-tech-and-design/en-gb/copy.json';

i18next.addResourceBundle(EN_GB, PAGE_NAMESPACE, copyEnGb);

export default CareersTechAndDesign;
